import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from "../views/Home.vue";
import Layout from '@/layout/index.vue';
Vue.use(VueRouter);
var scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return {
    x: 0,
    y: 0
  };
};
var app = [{
  path: '/app',
  component: Layout,
  // component: () => import('@/views/app/index.vue'),
  redirect: '/app',
  meta: {
    title: '应用管理'
  },
  static: "@/assets/home/app.png",
  children: [{
    path: '/app',
    name: 'App',
    component: function component() {
      return import('@/views/app/app.vue');
    },
    meta: {
      title: '应用管理'
    }
  }, {
    path: '/app/manage',
    name: 'Manage',
    // redirect: '/app/manage/platform',
    component: function component() {
      return import('@/views/app/manage.vue');
    },
    meta: {
      title: '版本信息'
    },
    children: [{
      path: '/app/manage/platform',
      redirect: '/app/manage/platform/version',
      name: 'Platform',
      component: function component() {
        return import('@/views/app/platform.vue');
      },
      meta: {
        title: 'App 平台'
      },
      children: [{
        path: '/app/manage/platform/version',
        name: 'Version',
        component: function component() {
          return import('@/views/app/versionInfo.vue');
        },
        meta: {
          title: 'App 平台'
        }
      }, {
        path: '/app/manage/platform/versionHistory',
        name: 'VersionHistory',
        component: function component() {
          return import('@/views/app/historyVersions.vue');
        },
        meta: {
          title: 'App 平台'
        }
      }]
    }, {
      path: '/app/manage/function',
      redirect: '/app/manage/function/appInfo',
      name: 'Function',
      component: function component() {
        return import('@/views/app/function/function.vue');
      },
      meta: {
        title: 'App 功能'
      },
      children: [{
        path: '/app/manage/function/appInfo',
        name: 'AppInfo',
        component: function component() {
          return import('@/views/app/function/appInfo.vue');
        },
        meta: {
          title: 'App信息'
        }
      }, {
        path: '/app/manage/function/activableBook',
        name: 'ActivableBook',
        component: function component() {
          return import('@/views/app/function/activableBook.vue');
        },
        meta: {
          title: '可激活图书'
        }
      }, {
        path: '/app/manage/function/resource',
        name: 'Resource',
        component: function component() {
          return import('@/views/app/function/resource.vue');
        },
        meta: {
          title: '包含资源'
        }
      }, {
        path: '/app/manage/function/indexManage',
        name: 'IndexManage',
        component: function component() {
          return import('@/views/app/function/indexManage.vue');
        },
        meta: {
          title: '首页管理'
        }
      }, {
        path: '/app/manage/function/column',
        name: 'Column',
        component: function component() {
          return import('@/views/app/function/column.vue');
        },
        meta: {
          title: '栏目'
        }
      }, {
        path: '/app/manage/function/banner',
        name: 'Banner',
        component: function component() {
          return import('@/views/app/function/banner.vue');
        },
        meta: {
          title: '轮播图管理'
        }
      }, {
        path: '/app/manage/function/monthlyupdate',
        name: 'Monthlyupdate',
        component: function component() {
          return import('@/views/app/function/monthlyupdate.vue');
        },
        meta: {
          title: '每月更新'
        }
      }, {
        path: '/app/manage/function/news',
        name: 'News',
        component: function component() {
          return import('@/views/app/function/news.vue');
        },
        meta: {
          title: '新闻'
        }
      }, {
        path: '/app/manage/function/mall',
        name: 'Mall',
        component: function component() {
          return import('@/views/app/function/mall.vue');
        },
        meta: {
          title: '商城'
        }
      }, {
        path: '/app/manage/function/message',
        name: 'Message',
        component: function component() {
          return import('@/views/app/function/message.vue');
        },
        meta: {
          title: '消息通知'
        }
      }, {
        path: '/app/manage/function/notice',
        name: 'Notice',
        component: function component() {
          return import('@/views/app/function/notice.vue');
        },
        meta: {
          title: '更新公告'
        }
      }, {
        path: '/app/manage/function/coin',
        name: 'Coin',
        component: function component() {
          return import('@/views/app/function/coin.vue');
        },
        meta: {
          title: 'App内购买项目'
        }
      }, {
        path: '/app/manage/function/memberOrder',
        name: 'MemberOrder',
        component: function component() {
          return import('@/views/app/function/memberOrder.vue');
        },
        meta: {
          title: 'App内会员订阅'
        }
      }]
    }, {
      path: '/app/manage/function/message/create',
      name: 'CreateMessage',
      component: function component() {
        return import('@/views/app/function/editMessage.vue');
      },
      meta: {
        title: '消息通知'
      }
    }, {
      path: '/app/manage/function/message/edit/:id',
      name: 'EditMessage',
      component: function component() {
        return import('@/views/app/function/editMessage.vue');
      },
      meta: {
        title: '消息通知'
      }
    }, {
      path: '/app/manage/function/notice/create',
      name: 'CreateNotice',
      component: function component() {
        return import('@/views/app/function/editNotice.vue');
      },
      meta: {
        title: '更新公告'
      }
    }, {
      path: '/app/manage/function/notice/edit/:id',
      name: 'EditNotice',
      component: function component() {
        return import('@/views/app/function/editNotice.vue');
      },
      meta: {
        title: '更新公告'
      }
    }, {
      path: '/app/manage/function/news/create',
      name: 'CreateNews',
      component: function component() {
        return import('@/views/app/function/editNews.vue');
      },
      meta: {
        title: '新闻'
      }
    }, {
      path: '/app/manage/function/news/edit/:id',
      name: 'EditNews',
      component: function component() {
        return import('@/views/app/function/editNews.vue');
      },
      meta: {
        title: '新闻'
      }
    }, {
      path: '/app/manage/function/commodity/edit/:id',
      name: 'EditCommodity',
      component: function component() {
        return import('@/views/app/function/editCommodity.vue');
      },
      meta: {
        title: '商品'
      }
    }, {
      path: '/app/manage/function/commodity/create',
      name: 'CreateCommodity',
      component: function component() {
        return import('@/views/app/function/editCommodity.vue');
      },
      meta: {
        title: '商品'
      }
    }, {
      path: '/app/manage/order',
      redirect: '/app/manage/order/rechargeOrder',
      name: 'AppOrder',
      component: function component() {
        return import('@/views/app/order/order.vue');
      },
      meta: {
        title: '订单'
      },
      children: [{
        path: '/app/manage/order/rechargeOrder',
        name: 'RechargeOrder',
        component: function component() {
          return import('@/views/app/order/rechargeOrder.vue');
        },
        meta: {
          title: '代币充值'
        }
      }, {
        path: '/app/manage/order/memberOrder',
        name: 'MemberOrderList',
        component: function component() {
          return import('@/views/app/order/memberOrder.vue');
        },
        meta: {
          title: '会员订阅'
        }
      }, {
        path: '/app/manage/order/resourceOrder',
        name: 'ResourceOrder',
        component: function component() {
          return import('@/views/app/order/resourceOrder.vue');
        },
        meta: {
          title: '资源购买'
        }
      }, {
        path: '/app/manage/order/bookOrder',
        name: 'BookOrderList',
        component: function component() {
          return import('@/views/app/order/bookOrder.vue');
        },
        meta: {
          title: '图书购买'
        }
      }, {
        path: '/app/manage/order/orgOrder',
        name: 'orgOrderList',
        component: function component() {
          return import('@/views/app/order/orgOrder.vue');
        },
        meta: {
          title: '企业订单'
        }
      }]
    }, {
      path: '/app/manage/behavior',
      redirect: '/app/manage/behavior/userLoginStatlist',
      name: 'AppBehavior',
      component: function component() {
        return import('@/views/app/behavior/behavior.vue');
      },
      meta: {
        title: '行为记录'
      },
      children: [{
        path: '/app/manage/behavior/userLoginStatlist',
        name: 'UserLoginStatlist',
        component: function component() {
          return import('@/views/app/behavior/userLoginStatlist.vue');
        },
        meta: {
          title: '登录统计'
        }
      }, {
        path: '/app/manage/behavior/coinRechargeList',
        name: 'CoinRechargeList',
        component: function component() {
          return import('@/views/app/behavior/coinRechargeList.vue');
        },
        meta: {
          title: '代币充值记录'
        }
      }, {
        path: '/app/manage/behavior/coinNoRechargeList',
        name: 'CoinNoRechargeList',
        component: function component() {
          return import('@/views/app/behavior/coinNoRechargeList.vue');
        },
        meta: {
          title: '未支付订单'
        }
      }, {
        path: '/app/manage/behavior/experience',
        name: 'Experience',
        component: function component() {
          return import('@/views/app/behavior/experience.vue');
        },
        meta: {
          title: '资源体验记录'
        }
      }, {
        path: '/app/manage/behavior/usage',
        name: 'Usage',
        component: function component() {
          return import('@/views/app/behavior/usage.vue');
        },
        meta: {
          title: '资源使用记录'
        }
      }, {
        path: '/app/manage/behavior/memberRecordList',
        name: 'MemberRecordList',
        component: function component() {
          return import('@/views/app/behavior/memberRecordList.vue');
        },
        meta: {
          title: '会员开通记录'
        }
      }, {
        path: '/app/manage/behavior/coinAddList',
        name: 'CoinAddList',
        component: function component() {
          return import('@/views/app/behavior/coinAddList.vue');
        },
        meta: {
          title: '代币增加记录'
        }
      }, {
        path: '/app/manage/behavior/productList',
        name: 'ProductList',
        component: function component() {
          return import('@/views/app/behavior/productList.vue');
        },
        meta: {
          title: '代币消费记录'
        }
      }, {
        path: '/app/manage/behavior/memberGrantList',
        name: 'MemberGrantList',
        component: function component() {
          return import('@/views/app/behavior/memberGrantList.vue');
        },
        meta: {
          title: '赠送会员记录'
        }
      }, {
        path: '/app/manage/behavior/couponList',
        name: 'CouponList',
        component: function component() {
          return import('@/views/app/behavior/couponList.vue');
        },
        meta: {
          title: '兑换与优惠记录'
        }
      }, {
        path: '/app/manage/behavior/coinGrantList',
        name: 'CoinGrantList',
        component: function component() {
          return import('@/views/app/behavior/coinGrantList.vue');
        },
        meta: {
          title: '赠送科学币记录'
        }
      }]
    }, {
      path: '/app/manage/user',
      redirect: '/app/manage/user/userList',
      name: 'AppUser',
      component: function component() {
        return import('@/views/app/user/user.vue');
      },
      meta: {
        title: '用户'
      },
      children: [{
        path: '/app/manage/user/userList',
        name: 'UserList',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '全部用户'
        }
      }, {
        path: '/app/manage/user/memberUserList',
        name: 'MemberUserList',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '会员用户'
        }
      }, {
        path: '/app/manage/user/userRechargeList',
        name: 'UserRechargeList',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '充值用户'
        }
      }, {
        path: '/app/manage/user/bookUserList',
        name: 'BookUserList',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '图书用户'
        }
      }, {
        path: '/app/manage/user/internalUserList',
        name: 'InternalUserList',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '测试用户'
        }
      }, {
        path: '/app/manage/user/userListLocked',
        name: 'UserListLocked',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '已锁定'
        }
      }, {
        path: '/app/manage/user/userListDisabled',
        name: 'UserListDisabled',
        component: function component() {
          return import('@/views/app/user/userList.vue');
        },
        meta: {
          title: '已禁用'
        }
      }]
    }, {
      path: '/app/manage/versionInfo',
      name: 'VersionInfo',
      component: function component() {
        return import('@/views/app/versionInfo.vue');
      },
      meta: {
        title: '版本信息'
      }
    }, {
      path: '/app/manage/buy',
      name: 'Buy',
      component: function component() {
        return import('@/views/app/buy.vue');
      },
      meta: {
        title: 'App内购买项目'
      }
    }, {
      path: '/app/manage/subscribe',
      name: 'Subscribe',
      component: function component() {
        return import('@/views/app/subscribe.vue');
      },
      meta: {
        title: 'App内会员订阅'
      }
    }, {
      path: '/app/manage/subscribe/info',
      name: 'SubscribeInfo',
      component: function component() {
        return import('@/views/app/subscribeInfo.vue');
      },
      meta: {
        title: 'App内会员订阅'
      }
    }, {
      path: '/app/manage/historyVersions',
      name: 'HistoryVersions',
      component: function component() {
        return import('@/views/app/historyVersions.vue');
      },
      meta: {
        title: '历史版本'
      }
    }]
  }, {
    path: '/app/manage/function/moudle/:id/resource',
    name: 'MoudleResource',
    component: function component() {
      return import('@/views/app/function/resouresTbale.vue');
    },
    meta: {
      title: '全部资源'
    }
  }, {
    path: '/app/manage/function/moudle/:id/topic',
    name: 'MoudleTopic',
    component: function component() {
      return import('@/views/app/function/resouresTbale.vue');
    },
    meta: {
      title: '全部主题'
    }
  }, {
    path: '/app/userDetail/:id',
    name: 'UserDetail',
    redirect: '/app/userDetail/:id/info',
    component: function component() {
      return import('@/views/app/userDetail/userDetail.vue');
    },
    meta: {
      title: '用户详情'
    },
    children: [{
      path: '/app/userDetail/:id/info',
      name: 'UserDetailInfo',
      component: function component() {
        return import('@/views/app/userDetail/UserDetailInfo.vue');
      },
      meta: {
        title: '信息'
      }
    }, {
      path: '/app/userDetail/:id/coin',
      name: 'UserDetailOrder',
      redirect: '/app/userDetail/:id/coin/rechargeOrder',
      component: function component() {
        return import('@/views/app/userDetail/UserDetailOrder.vue');
      },
      meta: {
        title: '消费'
      },
      children: [{
        path: '/app/userDetail/:id/coin/rechargeOrder',
        name: 'UserRechargeOrder',
        component: function component() {
          return import('@/views/app/order/rechargeOrder.vue');
        },
        meta: {
          title: '代币充值'
        }
      }, {
        path: '/app/userDetail/:id/coin/memberOrder',
        name: 'UserMemberOrderList',
        component: function component() {
          return import('@/views/app/order/memberOrder.vue');
        },
        meta: {
          title: '会员订阅'
        }
      }, {
        path: '/app/userDetail/:id/coin/resourceOrder',
        name: 'UserResourceOrder',
        component: function component() {
          return import('@/views/app/order/resourceOrder.vue');
        },
        meta: {
          title: '资源购买'
        }
      }, {
        path: '/app/userDetail/:id/coin/bookOrder',
        name: 'UserBookOrderList',
        component: function component() {
          return import('@/views/app/order/bookOrder.vue');
        },
        meta: {
          title: '图书购买'
        }
      }]
    }, {
      path: '/app/userDetail/:id/behavior/test',
      name: 'UserDetailBehavior',
      component: function component() {
        return import('@/views/app/userDetail/UserDetailBehavior.vue');
      },
      meta: {
        title: '行为'
      }
    }]
  }, {
    path: '/app/catalog/:columnId?',
    name: 'Catalog',
    redirect: '/app/catalog/:columnId?/list',
    component: function component() {
      return import('@/views/app/catalog.vue');
    },
    meta: {
      title: '版本信息'
    },
    children: [{
      path: '/app/catalog/:columnId?/list',
      // redirect: '/app/manage/platform/version',
      name: 'CatalogList',
      component: function component() {
        return import('@/views/app/catalog/list.vue');
      },
      meta: {
        title: '一级目录'
      },
      children: [{
        path: '/app/catalog/:columnId/list/:id',
        name: 'CatalogDetail',
        component: function component() {
          return import('@/views/app/catalog/detail.vue');
        },
        meta: {
          title: 'App 平台'
        }
      }]
    }]
  }, {
    path: '/app/member/:id',
    name: 'Member',
    component: function component() {
      return import('@/views/app/member/member.vue');
    },
    meta: {
      title: '版本信息'
    }
  }]
}];
var channel = [{
  path: '/channel',
  component: Layout,
  redirect: '/channel',
  meta: {
    title: '渠道管理'
  },
  static: "@/assets/home/channel.png",
  children: [{
    path: '/channel',
    name: 'Channel',
    redirect: '/channel/promotionDownload',
    component: function component() {
      return import('@/views/channel/index.vue');
    },
    meta: {
      title: '渠道管理'
    },
    children: [{
      path: '/channel/promotionDownload',
      name: 'PromotionDownload',
      component: function component() {
        return import('@/views/channel/promotionDownload/index.vue');
      },
      meta: {
        title: '推广下载渠道'
      }
    }, {
      path: '/channel/coupon',
      name: 'Coupon',
      component: function component() {
        return import('@/views/channel/coupon/index.vue');
      },
      meta: {
        title: '会员代金券渠道'
      }
    }, {
      path: '/channel/register',
      name: 'Register',
      component: function component() {
        return import('@/views/channel/register/index.vue');
      },
      meta: {
        title: '推荐注册渠道'
      }
    }, {
      path: '/channel/publish',
      name: 'Publish',
      component: function component() {
        return import('@/views/channel/publish/index.vue');
      },
      meta: {
        title: '出版商渠道'
      }
    }]
  },
  // 推广下载渠道
  {
    path: '/channel/promotionDownload/manage',
    name: 'PromotionDownloadManage',
    redirect: '/channel/promotionDownload/detail',
    component: function component() {
      return import('@/views/channel/promotionDownload/manage.vue');
    },
    meta: {
      title: '渠道详情'
    },
    children: [{
      path: '/channel/promotionDownload/detail',
      name: 'PromotionDetail',
      component: function component() {
        return import('@/views/channel/promotionDownload/detail.vue');
      },
      meta: {
        title: '渠道信息'
      }
    }, {
      path: '/channel/promotionDownload/commodity',
      name: 'PromotionDownloadCommodity',
      component: function component() {
        return import('@/views/channel/promotionDownload/commodity.vue');
      },
      meta: {
        title: '商品'
      }
    }, {
      path: '/channel/promotionDownload/coupons',
      name: 'CommodityCoupons',
      component: function component() {
        return import('@/views/channel/promotionDownload/commodityCoupons.vue');
      },
      meta: {
        title: '兑换券码'
      }
    }, {
      path: '/channel/promotionDownload/redeemCode',
      name: 'RedeemCode',
      // redirect: '/channel/promotionDownload/redeemCode',
      component: function component() {
        return import('@/views/channel/promotionDownload/redeemCode.vue');
      },
      meta: {
        title: '查看兑换码'
      },
      children: []
    }, {
      path: '/channel/promotionDownload/channelUser',
      name: 'channelUser',
      component: function component() {
        return import('@/views/channel/promotionDownload/channelUser.vue');
      },
      meta: {
        title: '用户'
      }
    }]
  },
  // 查看兑换码 RedeemCode
  // {
  //   path: '/channel/promotionDownload/redeemCode',
  //   name: 'RedeemCode',
  //   redirect: '/channel/promotionDownload/redeemCode',
  //   component: () => import('@/views/channel/promotionDownload/redeemCode.vue'),
  //   meta: {title: '查看兑换码'},
  //   children: []
  // },
  // {
  //   path: '/channel/promotionDownload/redeemCode',
  //   name: 'RedeemCode',
  //   redirect: '/channel/promotionDownload/redeemCode',
  //   component: () => import('@/views/channel/promotionDownload/redeemCode.vue'),
  //   meta: { title: '查看兑换码' },
  //   children: []
  // },

  // 代金券渠道
  {
    path: '/channel/coupon/manage',
    name: 'CouponManage',
    redirect: '/channel/coupon/detail',
    component: function component() {
      return import('@/views/channel/coupon/manage.vue');
    },
    meta: {
      title: '渠道详情'
    },
    children: [{
      path: '/channel/coupon/detail',
      name: 'CouponManageDetail',
      component: function component() {
        return import('@/views/channel/coupon/detail.vue');
      },
      meta: {
        title: '渠道信息'
      }
    }, {
      path: '/channel/coupon/commodity',
      name: 'Commodity',
      component: function component() {
        return import('@/views/channel/coupon/commodity.vue');
      },
      meta: {
        title: '商品'
      }
    }, {
      path: '/channel/coupon/secondaryChannel',
      name: 'SecondaryChannel',
      component: function component() {
        return import('@/views/channel/coupon/secondaryChannel.vue');
      },
      meta: {
        title: '二级渠道'
      }
    }, {
      path: '/channel/coupon/couponCode',
      name: 'CouponCode',
      component: function component() {
        return import('@/views/channel/coupon/couponCode.vue');
      },
      meta: {
        title: '查看兑换码'
      },
      children: []
    }, {
      path: '/channel/coupon/couponUser',
      name: 'couponUser',
      component: function component() {
        return import('@/views/channel/coupon/couponUser.vue');
      },
      meta: {
        title: '用户'
      }
    }]
  },
  // 优惠券渠道——二级渠道
  {
    path: '/channel/coupon/secondaryManage',
    name: 'SecondaryManage',
    redirect: '/channel/coupon/secondaryDetail',
    component: function component() {
      return import('@/views/channel/coupon/secondaryManage.vue');
    },
    meta: {
      title: '二级渠道'
    },
    children: [{
      path: '/channel/coupon/secondaryDetail',
      name: 'SecondaryDetail',
      component: function component() {
        return import('@/views/channel/coupon/secondaryDetail.vue');
      },
      meta: {
        title: '二级渠道信息'
      }
    }, {
      path: '/channel/coupon/secondaryCommodity',
      name: 'SecondaryCommodity',
      component: function component() {
        return import('@/views/channel/coupon/secondaryCommodity.vue');
      },
      meta: {
        title: '商品'
      }
    }]
  },
  // 推荐注册渠道
  {
    path: '/channel/register/manage',
    name: 'RegisterManage',
    redirect: '/channel/register/detail',
    component: function component() {
      return import('@/views/channel/register/manage.vue');
    },
    meta: {
      title: '渠道详情'
    },
    children: [{
      path: '/channel/register/detail',
      name: 'RegisterManageDetail',
      component: function component() {
        return import('@/views/channel/register/detail.vue');
      },
      meta: {
        title: '渠道信息'
      }
    }, {
      path: '/channel/register/secondaryChannel',
      name: 'RegisterSecondaryChannel',
      component: function component() {
        return import('@/views/channel/register/secondaryChannel.vue');
      },
      meta: {
        title: '二级渠道'
      }
    }]
  },
  // 推荐注册渠道——二级渠道
  {
    path: '/channel/register/secondaryManage',
    name: 'RegisterSecondaryManage',
    redirect: '/channel/register/secondaryDetail',
    component: function component() {
      return import('@/views/channel/register/secondaryManage.vue');
    },
    meta: {
      title: '二级渠道'
    },
    children: [{
      path: '/channel/register/secondaryDetail',
      name: 'RegisterSecondaryDetail',
      component: function component() {
        return import('@/views/channel/register/secondaryDetail.vue');
      },
      meta: {
        title: '二级渠道信息'
      }
    }]
  }, {
    path: '/channel/publish/manage',
    name: 'PublishManage',
    redirect: '/channel/publish/detail',
    component: function component() {
      return import('@/views/channel/publish/manage.vue');
    },
    meta: {
      title: '渠道详情'
    },
    children: [{
      path: '/channel/publish/detail',
      name: 'PublishDetail',
      component: function component() {
        return import('@/views/channel/publish/detail.vue');
      },
      meta: {
        title: '渠道信息'
      }
    }, {
      path: '/channel/publish/bookSellInfo',
      name: 'BookSellInfo',
      component: function component() {
        return import('@/views/channel/publish/bookSellInfo.vue');
      },
      meta: {
        title: '图书销售信息'
      }
    }, {
      path: '/channel/publish/commodity',
      name: 'PublishCommodity',
      component: function component() {
        return import('@/views/channel/publish/commodity.vue');
      },
      meta: {
        title: '商品'
      }
    }, {
      path: '/channel/publish/publishUser',
      name: 'PublishUser',
      component: function component() {
        return import('@/views/channel/publish/publishUser.vue');
      },
      meta: {
        title: '用户'
      }
    }]
  }]
}];

// 客户

var client = [{
  path: '/client',
  component: Layout,
  redirect: '/client',
  meta: {
    title: '企业客户'
  },
  static: "@/assets/home/channel.png",
  children: [{
    path: '/client',
    name: 'Client',
    redirect: '/client/pc',
    component: function component() {
      return import('@/views/client/index.vue');
    },
    meta: {
      title: '客户类型'
    },
    children: [{
      path: '/client/pc',
      name: 'PC',
      component: function component() {
        return import('@/views/client/pc/index.vue');
      },
      meta: {
        title: 'PC端'
      }
    }, {
      path: '/client/screen',
      name: 'Screen',
      component: function component() {
        return import('@/views/client/screen/index.vue');
      },
      meta: {
        title: '大屏'
      }
    }]
  }, {
    path: '/client/pc/manage',
    name: 'PCManage',
    redirect: '/client/pc/detail',
    component: function component() {
      return import('@/views/client/pc/manage.vue');
    },
    meta: {
      title: 'PC端渠道详情'
    },
    children: [{
      path: '/client/pc/detail',
      name: 'PCDetail',
      component: function component() {
        return import('@/views/client/pc/detail.vue');
      },
      meta: {
        title: '渠道详情'
      }
    }, {
      path: '/client/pc/member',
      name: 'PCMember',
      component: function component() {
        return import('@/views/client/pc/member.vue');
      },
      meta: {
        title: '成员'
      }
    }]
  }, {
    path: '/client/pc/memberManage',
    name: 'PCMemberManage',
    redirect: '/client/pc/memberManage',
    component: function component() {
      return import('@/views/client/pc/memberManage.vue');
    },
    meta: {
      title: '成员信息'
    }
  }, {
    path: '/client/pc/memberDetailManage',
    name: 'MemberDetailManage',
    redirect: '/client/pc/memberDetail',
    component: function component() {
      return import('@/views/client/pc/memberDetailManage.vue');
    },
    meta: {
      title: '成员详情'
    },
    children: [{
      path: '/client/pc/memberDetail',
      name: 'MemberDetail',
      component: function component() {
        return import('@/views/client/pc/memberDetail.vue');
      },
      meta: {
        title: '成员信息'
      }
    }, {
      path: '/client/pc/resourceAuthority',
      name: 'ResourceAuthority',
      component: function component() {
        return import('@/views/client/pc/resourceAuthority.vue');
      },
      meta: {
        title: '资源权限'
      }
    }, {
      path: '/client/pc/useRecord',
      name: 'UseRecord',
      component: function component() {
        return import('@/views/client/pc/useRecord.vue');
      },
      meta: {
        title: '使用记录'
      }
    }]
  },
  // 大屏
  {
    path: '/client/screen/manage',
    name: 'ScreenManage',
    redirect: '/client/screen/detail',
    component: function component() {
      return import('@/views/client/screen/manage.vue');
    },
    meta: {
      title: '借阅大屏渠道详情'
    },
    children: [{
      path: '/client/screen/detail',
      name: 'ScreenDetail',
      component: function component() {
        return import('@/views/client/screen/detail.vue');
      },
      meta: {
        title: '借阅大屏渠道详情'
      }
    }, {
      path: '/client/screen/member',
      name: 'ScreenMember',
      component: function component() {
        return import('@/views/client/screen/member.vue');
      },
      meta: {
        title: '成员'
      }
    }]
  }, {
    path: '/client/screen/memberManage',
    name: 'ScreenMemberManage',
    redirect: '/client/screen/memberManage',
    component: function component() {
      return import('@/views/client/screen/memberManage.vue');
    },
    meta: {
      title: '成员信息'
    }
  }, {
    path: '/client/screen/memberDetailManage',
    name: 'ScreenMemberDetailManage',
    redirect: '/client/screen/memberDetail',
    component: function component() {
      return import('@/views/client/screen/memberDetailManage.vue');
    },
    meta: {
      title: '成员详情'
    },
    children: [{
      path: '/client/screen/memberDetail',
      name: 'ScreenMemberDetail',
      component: function component() {
        return import('@/views/client/screen/memberDetail.vue');
      },
      meta: {
        title: '成员信息'
      }
    }, {
      path: '/client/screen/resourceAuthority',
      name: 'ScreenResourceAuthority',
      component: function component() {
        return import('@/views/client/screen/resourceAuthority.vue');
      },
      meta: {
        title: '资源权限'
      }
    }, {
      path: '/client/screen/useRecord',
      name: 'ScreenUseRecord',
      component: function component() {
        return import('@/views/client/screen/useRecord.vue');
      },
      meta: {
        title: '使用记录'
      }
    }]
  }]
}];

// 资源
var assets = [{
  path: '/assets',
  component: Layout,
  redirect: '/assets',
  children: [{
    path: '/assets',
    name: 'Assets',
    // component: () => import('@/views/assets/index.vue'),
    component: function component() {
      return import('@/views/assets/index.vue');
    },
    meta: {
      title: '资源管理'
    },
    children: [{
      path: '/assets/resourceList',
      name: 'ResourceList',
      component: function component() {
        return import('@/views/assets/resourceList.vue');
      },
      meta: {
        title: '全部资源'
      }
    }, {
      path: '/assets/topicList',
      name: 'TopicList',
      component: function component() {
        return import('@/views/assets/topicList.vue');
      },
      meta: {
        title: '全部主题'
      }
    }, {
      path: '/assets/cataloguePage',
      name: 'CataloguePage',
      component: function component() {
        return import('@/views/assets/components/cataloguePage.vue');
      },
      meta: {
        title: '批量匹配目录'
      }
    }, {
      path: '/assets/uploadFileAddPage',
      name: 'UploadFileAddPage',
      component: function component() {
        return import('@/views/assets/components/uploadFileAddPage.vue');
      },
      meta: {
        title: '批量上传brio文件'
      }
    }]
  }]
}];
var resoures = [{
  path: '/resoure',
  component: Layout,
  redirect: '/resoure',
  children: [{
    path: '/resoure',
    name: 'resoure',
    component: function component() {
      return import('@/views/resoure/resoureList.vue');
    },
    meta: {
      title: '资源管理'
    }
  }, {
    path: '/resoure/uploadFileAddPage',
    name: 'UploadFileAddPage',
    component: function component() {
      return import('@/views/resoure/components/uploadFileAddPage.vue');
    },
    meta: {
      title: '批量上传brio文件'
    }
  }, {
    path: '/resoure/batchMatchCatalog',
    name: 'batchMatchCatalog',
    component: function component() {
      return import('@/views/resoure/components/batchMatchCatalog.vue');
    },
    meta: {
      title: '批量匹配目录'
    }
  }, {
    path: '/resoure/themeDetail',
    name: 'themeDetail',
    component: function component() {
      return import('@/views/resoure/components/themeDetail.vue');
    },
    meta: {
      title: '主题详情'
    }
  }, {
    path: '/resoure/versionMessageSummary',
    name: 'versionMessageSummary',
    component: function component() {
      return import('@/views/resoure/components/versionMessageSummary.vue');
    },
    meta: {
      title: '资源详情',
      keepAlive: false
    }
  }, {
    path: '/resoure/workBookEditOrNew',
    name: 'workBookEditOrNew',
    component: function component() {
      return import('@/views/resoure/components/workBookEditOrNew.vue');
    },
    meta: {
      title: '编辑习题'
    }
  }]
}];
var book = [{
  path: '/book',
  component: Layout,
  redirect: '/book',
  meta: {
    title: '图书管理'
  },
  static: "@/assets/home/book.png",
  children: [{
    path: '/book',
    name: 'Book',
    redirect: '/book/books',
    component: function component() {
      return import('@/views/book/index.vue');
    },
    meta: {
      title: '图书管理'
    },
    children: [{
      path: '/book/books',
      name: 'Books',
      redirect: '/book/books/all',
      component: function component() {
        return import('@/views/book/components/menu.vue');
      },
      meta: {
        title: '图书管理'
      },
      children: [{
        path: '/book/books/all',
        name: 'AllBooks',
        component: function component() {
          return import('@/views/book/allBook.vue');
        },
        meta: {
          title: '图书管理'
        }
      }, {
        path: '/book/books/publisher',
        name: 'Publisher',
        component: function component() {
          return import('@/views/book/publisher.vue');
        },
        meta: {
          title: '图书管理'
        }
      }, {
        path: '/book/books/purchase',
        name: 'Purchase',
        component: function component() {
          return import('@/views/book/purchase.vue');
        },
        meta: {
          title: '图书管理'
        }
      }, {
        path: '/book/books/order',
        name: 'Order',
        component: function component() {
          return import('@/views/book/order.vue');
        },
        meta: {
          title: '图书管理'
        }
      }]
    }, {
      path: '/book/info',
      name: 'BookInfo',
      redirect: '/book/info/detail',
      component: function component() {
        return import('@/views/book/bookInfoMenu.vue');
      },
      meta: {
        title: '图书详情'
      },
      children: [{
        path: '/book/info/detail',
        name: 'Detail',
        component: function component() {
          return import('@/views/book/bookInfo.vue');
        },
        meta: {
          title: '图书详情'
        }
      }, {
        path: '/book/info/resources',
        name: 'Resources',
        component: function component() {
          return import('@/views/book/resources.vue');
        },
        meta: {
          title: '图书详情'
        }
      }, {
        path: '/book/info/activation',
        name: 'Activation',
        component: function component() {
          return import('@/views/book/activation.vue');
        },
        meta: {
          title: '图书详情'
        }
      }, {
        path: '/book/info/identification',
        name: 'Identification',
        component: function component() {
          return import('@/views/book/identification.vue');
        },
        meta: {
          title: '图书详情'
        }
      }, {
        path: '/book/info/salesArea',
        name: 'SalesArea',
        component: function component() {
          return import('@/views/book/salesArea.vue');
        },
        meta: {
          title: '图书详情'
        }
      }]
    }]
  }]
}];
var setting = [{
  path: '/setting',
  component: Layout,
  redirect: '/setting',
  children: [{
    path: '/setting',
    name: 'Setting',
    component: function component() {
      return import('@/views/setting/setting.vue');
    },
    meta: {
      title: '系统设置'
    },
    redirect: '/setting/admin',
    children: [{
      path: '/setting/admin',
      name: 'Admin',
      component: function component() {
        return import('@/views/setting/admin.vue');
      },
      meta: {
        title: '管理员设置'
      }
    }, {
      path: '/setting/role',
      name: 'Role',
      component: function component() {
        return import('@/views/setting/role.vue');
      },
      meta: {
        title: '角色'
      }
    }, {
      path: '/setting/useractionmonitor',
      name: 'Useractionmonitor',
      component: function component() {
        return import('@/views/setting/useractionmonitor.vue');
      },
      meta: {
        title: '操作日志'
      }
    }, {
      path: '/setting/loginlog',
      name: 'Loginlog',
      component: function component() {
        return import('@/views/setting/loginlog.vue');
      },
      meta: {
        title: '登录日志'
      }
    }, {
      path: '/setting/roleUser',
      name: 'RoleUser',
      component: function component() {
        return import('@/views/setting/admin.vue');
      },
      meta: {
        title: '角色成员'
      }
    }]
  }]
}];
var feedback = [{
  path: '/feedback',
  component: Layout,
  redirect: '/feedback',
  children: [{
    path: '/feedback',
    name: 'Feedback',
    component: function component() {
      return import('@/views/feedback/feedback.vue');
    },
    meta: {
      title: '用户反馈'
    }
  }, {
    path: '/feedbackManage',
    name: 'FeedbackManage',
    redirect: '/feedbackManage/question',
    component: function component() {
      return import('@/views/feedback/feedbackManage.vue');
    },
    meta: {
      title: '用户反馈'
    },
    children: [{
      path: '/feedbackManage/question',
      name: 'Question',
      component: function component() {
        return import('@/views/feedback/question.vue');
      },
      meta: {
        title: '我想知道'
      }
    }, {
      path: '/feedbackManage/bizCooperate',
      name: 'BizCooperate',
      component: function component() {
        return import('@/views/feedback/bizCooperate.vue');
      },
      meta: {
        title: '商务合作'
      }
    }, {
      path: '/feedbackManage/joinus',
      name: 'Joinus',
      component: function component() {
        return import('@/views/feedback/joinus.vue');
      },
      meta: {
        title: '代理加盟'
      }
    }, {
      path: '/feedbackManage/newfeedback',
      name: 'NewFeedback',
      component: function component() {
        return import('@/views/feedback/newfeedback.vue');
      },
      meta: {
        title: '意见反馈'
      }
    }, {
      path: '/feedbackManage/trialApply',
      name: 'TrialApply',
      component: function component() {
        return import('@/views/feedback/trialApply.vue');
      },
      meta: {
        title: '使用账号申请'
      }
    }]
  }]
}];
var analysis = [{
  path: '/analysis',
  component: Layout,
  redirect: '/analysis',
  children: [{
    path: '/analysis',
    name: 'Analysis',
    component: function component() {
      return import('@/views/analysis/analysis.vue');
    },
    meta: {
      title: 'App 分析'
    }
  }, {
    path: '/analysisManage',
    name: 'AnalysisManage',
    redirect: '/analysisManage/index',
    component: function component() {
      return import('@/views/analysis/analysisManage.vue');
    },
    meta: {
      title: 'App 分析'
    },
    children: [{
      path: '/analysisManage/index',
      name: 'AnalysisIndex',
      component: function component() {
        return import('@/views/analysis/analysisIndex.vue');
      },
      meta: {
        title: '指标'
      }
    }, {
      path: '/analysisManage/duration',
      name: 'AnalysisDuration',
      component: function component() {
        return import('@/views/analysis/analysisDuration.vue');
      },
      meta: {
        title: '每日持续使用情况'
      }
    }]
  }]
}];
var topicDetail = [{
  path: '/assetsDetail',
  component: Layout,
  redirect: '/assetsDetail',
  children: [{
    path: '/assetsDetail',
    name: 'AssetsDetail',
    component: function component() {
      return import('@/views/assetsDetail/index.vue');
    },
    meta: {
      title: '主题详情'
    },
    children: [{
      path: '/assetsDetail/resourceLis',
      name: 'AssResourceList',
      component: function component() {
        return import('@/views/assets/resourceList.vue');
      },
      meta: {
        title: '全部主题'
      }
    }]
  }]
}];
var routes = [
// {
//   path: '/',
//   name: 'Home',
//   component: Home
// },
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: function component() {
    return import( /* webpackChunkName: "about" */"../views/About.vue");
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'home',
    name: 'Home',
    component: function component() {
      return import('@/views/home/home.vue');
    },
    meta: {
      title: '首页',
      icon: 'home'
    }
  }]
}, {
  path: '/login',
  name: 'Login',
  component: function component() {
    return import('@/views/login/login');
  },
  hidden: true
}].concat(app, channel, resoures, assets, topicDetail, book, setting, feedback, analysis, client);
var createRouter = function createRouter() {
  return new VueRouter({
    // mode: 'history', // require service support
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    }
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;