import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "navMenuTab",
  props: {
    menuList: Array,
    initVal: String
  },
  data: function data() {
    return {
      expandTime: true,
      selectedCode: "",
      activeNames: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.expandTime = true;
    setTimeout(function () {
      _this.expandTime = false;
    }, 500);
    this.activeName = [];
    this.selectedCode = this.initVal || "";
    this.menuList.forEach(function (item, index) {
      _this.activeNames.push(index);
    });
  },
  methods: {
    clear: function clear() {
      this.selectedCode = this.initVal || "";
    },
    handleChange: function handleChange(item) {
      this.selectedCode = item.label;
      this.$emit("change", item);
    }
  }
};