import _objectSpread from "E:/run/sdkxv2_manage/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { app_platform_list as _app_platform_list, app_detail as _app_detail } from '@/api/app';
import { lan_list } from '@/api/lan';
export default {
  namespaced: true,
  state: function state() {
    return {
      app: null,
      platforms: null,
      langs: []
    };
  },
  mutations: {
    // 改数据函数
    SET_APP: function SET_APP(state, app) {
      state.app = app;
    },
    SET_PLATFORMS: function SET_PLATFORMS(state, platforms) {
      state.platforms = platforms;
    },
    SET_EMPTY_APP: function SET_EMPTY_APP(state) {
      state.app = null;
    },
    SET_LANGS: function SET_LANGS(state, langs) {
      state.langs = langs;
    }
  },
  actions: {
    // 请求数据函数
    app_platform_list: function app_platform_list(_ref, param) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        _app_platform_list(_objectSpread({}, param)).then(function (response) {
          var result = response.data.result;
          if (response.data.code) {
            commit('SET_PLATFORMS', result);
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },
    // 请求数据函数
    app_detail: function app_detail(_ref2, param) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        _app_detail(_objectSpread({}, param)).then(function (res) {
          commit('SET_APP', res.data);
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    },
    app_langs: function app_langs(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        lan_list({}).then(function (res) {
          commit('SET_LANGS', res.data);
        }).catch(function (error) {
          console.log(error);
          reject(error);
        });
      });
    }
  }
};