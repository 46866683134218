import _objectSpread from "E:/run/sdkxv2_manage/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  name: 'Login',
  data: function data() {
    return {
      dialogTableVisible: true
    };
  },
  computed: _objectSpread({}, mapState(['progress'])),
  created: function created() {
    console.log(process.env);
  },
  mounted: function mounted() {},
  methods: {}
};